import { ApiMethods } from "../enum/ApiMethods";
import ApiHelper from "./ApiHelper";
const api = require("../../../../storage/app/apiPath/api.json");



export default class ArticleApiHelper {
    public static getArticle(id = null): Promise<any> {
        if (id) {
            return ApiHelper.callSelf(ApiMethods.GET, `${api.article}/${id}`);
        } else {
            return ApiHelper.callSelf(ApiMethods.GET, `${api.article}`);
        }
    }
    public static getPage(): Promise<any> {
        return ApiHelper.callSelf(ApiMethods.GET, api.page);
    }
    public static contactUs(data) {
        return ApiHelper.callSelf(ApiMethods.POST, api.contactUs, data);
    }
}