<template>
  <div id="main-wrap" class="main-container">
    <div id="news" class="fcol fcol-1">
      <div class="container">
        <div class="edit-area">
          <p style="text-align: center">
            <strong><span style="font-size: 24px">最新消息</span></strong>
          </p>
          <p style="text-align: center">
            <span
              style="font-size: 18px; font-family: 'LiHei Pro', 微軟正黑體"
            ></span
            ><span style="color: #000000"
              ><a
                href="https://menews.info/2019/12/%e5%90%8d%e4%b8%ad%e9%86%ab%e6%a8%93%e4%b8%ad%e4%ba%ae%e7%b5%90%e5%90%88ai%e8%88%87%e5%a4%a7%e6%95%b8%e6%93%9a-%e6%88%90%e9%a0%90%e9%98%b2%e9%86%ab%e5%ad%b8%e6%96%b0%e4%ba%ae%e9%bb%9e/tw"
                style="color: #000000"
                ><span style="font-size: 18px"
                  >名中醫樓中亮結合AI與大數據 成預防醫學新亮點</span
                ></a
              ></span
            >
          </p>
          <p style="text-align: center">
            <span style="color: #000000"
              ><a
                href="https://www.businesstoday.com.tw/article/category/154768/post/201909110062/%E5%90%83%E6%B3%A1%E9%BA%B5%E5%8F%AF%E8%83%BD%E9%A4%8A%E5%87%BA%E7%99%8C%E7%97%87%EF%BC%9F%E9%86%AB%E5%B8%AB%EF%BC%9A%E6%9C%83%E7%A0%B4%E5%A3%9E%E5%BE%AA%E7%92%B0%E6%81%90%E8%87%B4%E7%99%8C...%E5%A4%9A%E5%90%836%E9%A3%9F%E7%89%A9%E6%98%AF%E4%BF%9D%E5%91%BD%E9%97%9C%E9%8D%B5%EF%BC%81"
                style="color: #000000"
                ><span
                  style="font-size: 18px; font-family: 'LiHei Pro', 微軟正黑體"
                  >吃泡麵可能養出癌症？醫師：會破壞循環恐致癌...多吃6食物是保命關鍵！</span
                ></a
              ></span
            >
          </p>
          <p style="text-align: center">
            <span style="color: #000000; background-color: #ffffff"
              ><a
                href="https://www.businesstoday.com.tw/article/category/154768/post/201909110040/%E9%A0%90%E9%98%B2%E7%99%8C%E7%B4%B0%E8%83%9E%E5%A2%9E%E7%94%9F%EF%BC%81%E9%86%AB%E5%B8%AB%EF%BC%9A%E5%A4%9A%E5%81%9A3%E5%8B%95%E4%BD%9C%E4%BF%83%E6%8E%92%E6%AF%92%E3%80%81%E7%B7%A9%E8%A7%A3%E7%96%B2%E5%8B%9E%E5%B0%B1%E6%98%AF%E5%9C%A8%E9%98%B2%E7%99%8C?fbclid=IwAR39zPY52l8LymWSbts0YMCjXKPHPRzEGesRCXbPcqWi97T6UCVSCbtJ06M"
                style="color: #000000; background-color: #ffffff"
                ><span style="font-size: 18px; background-color: #ffffff"
                  >預防癌細胞增生！醫師：多做3動作促排毒、緩解疲勞就是在防癌</span
                ></a
              ></span
            >
          </p>
          <p></p>
        </div>
      </div>
    </div>
    <div class="fcol fcol-2">
      <div class="container">
        <div id="nicedit_1" class="edit-area">
          <div class="x2">
            <p>
              <video
                width="100%"
                height="auto"
                style="
                  z-index: 51;
                  background-size: cover;
                  visibility: inherit;
                  opacity: 1;
                "
                muted=""
                playsinline=""
                autoplay="autoplay"
                class="resizelistener"
                loop="loop"
                preload="preload"
              >
                <source
                  style=""
                  src="/img/72a9f618-aa13-4ca2-a85f-30171f7fe2d8.mp4"
                  type="video/mp4"
                />
              </video>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="fcol fcol-3">
      <div class="container">
        <div id="nicedit_2" class="edit-area">
          <p style="text-align: center">
            <span style="color: #ffffff"
              ><strong
                ><span style="font-size: 24px">服務項目</span></strong
              ></span
            >
          </p>
          <div
            id="carouselExampleControls"
            class="carousel slide pointer-event"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item">
                <div class="col-sm-12">
                  <span style="color: #ffffff"
                    ><img
                      class="rounded-circle"
                      src="/img/97cbbc8a-0c6b-43d7-995a-f92c057be732.jpg"
                      alt="01.jpg"
                      width="300px"
                      style="
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                      "
                  /></span>
                  <div class="col-12" style="text-align: center">
                    <span style="color: #ffffff"
                      ><br /><span
                        style="
                          font-family: 'LiHei Pro', 微軟正黑體;
                          font-size: 20px;
                        "
                        >中醫診所</span
                      ><br /><br
                    /></span>
                  </div>
                </div>
              </div>
              <div class="carousel-item active">
                <div class="col-sm-12">
                  <span style="color: #ffffff"
                    ><img
                      class="rounded-circle"
                      src="/img/b733b72c-5985-4b28-ac1d-b8dbd2d2fb5e.jpg"
                      alt="01.jpg"
                      width="300px"
                      style="
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                      "
                      height=""
                  /></span>
                  <div class="col-12" style="text-align: center">
                    <br /><span
                      style="
                        font-family: 'LiHei Pro', 微軟正黑體;
                        color: #ffffff;
                        font-size: 20px;
                      "
                      >康養中心</span
                    ><br /><br />
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-sm-12">
                  <span style="color: #ffffff"
                    ><img
                      class="rounded-circle"
                      src="/img/c35b6619-cf34-4a05-b0f4-f6e9805aad5a.jpg"
                      alt="01.jpg"
                      width="300px"
                      style="
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                      "
                      height=""
                  /></span>
                  <div class="col-12" style="text-align: center">
                    <span style="color: #ffffff"
                      ><br /><span style="font-size: 20px">樓易健康</span
                      ><br /><br
                    /></span>
                  </div>
                </div>
              </div>
            </div>
            <span style="color: #ffffff"
              ><a
                class="carousel-control-prev"
                href="#carouselExampleControls"
                role="button"
                data-slide="prev"
                style="color: #ffffff"
                ><span class="carousel-control-prev-icon" aria-hidden="true"
                  ><img
                    src="/img/8db3b3d4-e948-4691-b94b-e67cabb5ba32.png"
                    alt="app.png" /></span
                ><span class="sr-only">Previous</span></a
              ><a
                class="carousel-control-next"
                href="#carouselExampleControls"
                role="button"
                data-slide="next"
                style="color: #ffffff"
                ><span class="carousel-control-next-icon" aria-hidden="true"
                  ><br /></span
                ><span class="sr-only">Next</span></a
              ></span
            >
          </div>
          <p></p>
        </div>
      </div>
    </div>
    <div class="fcol fcol-4">
      <div class="container">
        <div id="nicedit_3" class="edit-area">
          <p style="text-align: center">
            <span style="color: #ffffff; font-size: 24px"
              ><span style="font-family: 'LiHei Pro', 微軟正黑體"
                ><br />我們的理念<br /><span style="font-size: 18px"
                  >健康有道，調理有方<br /><br /></span></span></span
            ><span style="color: #ffffff; font-size: 24px"></span
            ><span style="color: #ffffff; font-size: 24px"
              ><span style="font-family: 'LiHei Pro', 微軟正黑體"></span
            ></span>
          </p>
          <p></p>
          <p></p>
          <div class="row">
            <div class="col-sm-4" style="text-align: center">
              <img
                src="/img/3f882c49-9f83-430b-999b-b23ceeb5c0ba.png"
                alt="我們的服務-算病.png"
                width="30%"
                height=""
              /><br /><span
                style="
                  font-size: 20px;
                  color: #ffffff;
                  font-family: 'LiHei Pro', 微軟正黑體;
                "
                >算病</span
              ><br /><br />
            </div>
            <div class="col-sm-4" style="text-align: center">
              <img
                src="/img/b8286cc9-07dc-4a6c-b550-1a69300b7abf.png"
                alt="我們的服務-防病.png"
                width="30%"
                height=""
                style="display: block; margin-left: auto; margin-right: auto"
              /><span
                style="
                  font-size: 20px;
                  color: #ffffff;
                  font-family: 'LiHei Pro', 微軟正黑體;
                "
                >防病</span
              >
            </div>
            <div class="col-sm-4" style="text-align: center">
              <img
                src="/img/cadb7211-a891-469c-ade6-a523920a035e.png"
                alt="我們的服務-治病.png"
                width="30%"
                height=""
                style="display: block; margin-left: auto; margin-right: auto"
              /><span
                style="
                  font-family: 'LiHei Pro', 微軟正黑體;
                  font-size: 20px;
                  color: #ffffff;
                "
                >治病</span
              >
            </div>
          </div>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
        </div>
      </div>
    </div>
    <div class="fcol fcol-5">
      <div class="container">
        <div class="edit-area">
          <div id="brand-story" class="row" style="padding: 0 1em">
            <div class="col-md-6 col-md-push-6" style="padding: 0 1em">
              <br /><br /><br /><img
                src="/img/9c65684f-d47d-4a2e-b9c8-716f0ebde686.jpg"
                alt=""
                width="100%"
                height=""
              />
            </div>
            <div class="col-md-6 col-md-pull-6" style="padding: 0 1em">
              <div
                class="
                  single_service single_service_left_text
                  wow
                  fadeInUp
                  animated
                "
                style="visibility: visible; animation-name: fadeInUp"
              >
                <h2 class="m-b-2" style="text-align: left"></h2>
                <h2 class="m-b-2" style="text-align: left"></h2>
                <h2 class="m-b-2" style="text-align: left"></h2>
                <h3 class="m-b-2" style="text-align: left"><br />品牌故事</h3>
                <p style="text-align: left"><br />醫者父母心。</p>
                <p style="text-align: left">
                  本著希望讓更多人健康的使命，知名中醫師樓中亮創辦了天訊國際控股集團，他將畢生鑽研且研究有成的「樓式古中醫」加上AI、大數據、影像辨識等科技元素，以大中華區為基地，組織團隊橫跨二岸三地，包括「樓易健康科技股份有限公司」與「樓易信息技術(上海)有限公司」等，研發樓易健康管理APP，希望用科技的力量，模糊線上與線下的邊界，讓更多人更簡單瞭解自身體質，從而更容易健康。
                </p>
                <p style="text-align: left">
                  除了APP的開發，天訊國際控股集團未來更預計拓展至新加坡、馬來西亞等地，希冀無論是在有形的疆界，或是無遠弗屆的網路中，都將持續發展更能幫助人們更健康的產品，有效推展中醫預防保健的風潮，讓人人都能容易健康。
                </p>
              </div>
            </div>
          </div>
          <div id="founder" class="x5x2">
            <div class="row" style="padding: 0 1em">
              <div class="col-md-6" style="padding: 0 1em">
                <div class="">
                  <br /><img
                    src="/img/904c867d-e238-4500-a72d-42ebae0e2cd4.jpg"
                    alt=""
                    width="100%"
                    height=""
                  />
                </div>
              </div>
              <div class="col-md-6" style="padding: 0 1em">
                <h3 class="m-b-2" style="text-align: left"><br />創辦人介紹</h3>
                <p style="text-align: left">
                  <br />樓中亮&mdash;台灣中醫預防保健協會創會理事長，現任「樓中亮中醫體系」院長。<br />天訊國際控股集團創辦人。<br />南京中醫藥大學醫學博士。
                </p>
                <p style="text-align: left">
                  多年致力鑽研「易經健康解碼」、「五運六氣天氣醫學」、<br />「掌紋全息療法」等諸多古中醫精髓，歷經二十多年的潛心研究，<br />獨創「樓氏古中醫」療法，並有多本暢銷著作，為華人地區知名中醫師。
                </p>
                <p style="text-align: left">
                  近年運用科技將「樓氏古中醫」與AI、大數據、影像辨識整合成一套「治未病」的預防保健系統&mdash;樓易健康管理APP，希望能幫助更多人容易健康，健康更容易。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fcol fcol-6">
      <div class="container">
        <div id="nicedit_5" class="edit-area">
          <div id="louyiai" class="row" style="padding: 0 1em">
            <div class="col-md-6 col-md-push-6" style="padding: 0 1em">
              <br /><br /><br /><img
                src="/img/94eb7edb-53ce-49eb-85b5-5878cbc71b2b.jpg"
                alt=""
                width="100%"
                height=""
              />
            </div>
            <div class="col-md-6 col-md-pull-6" style="padding: 0 1em">
              <div
                class="
                  single_service single_service_left_text
                  wow
                  fadeInUp
                  animated
                "
                style="visibility: visible; animation-name: fadeInUp"
              >
                <h2 class="m-b-2" style="text-align: left"></h2>
                <p></p>
                <h3 class="m-b-2" style="text-align: left">
                  <span style="color: #000000"><br /><br /><br />台灣樓易</span>
                </h3>
                <p>
                  <br />樓易健康股份有限公司。<br />為天訊控股集團旗下子公司，是樓易健康管理<span>APP</span>開發團隊之一。
                </p>
                <p>
                  結合樓中亮醫師研發的『樓氏古中醫』與<span>AI</span>人工智能、影像辨識等科技，以讓大眾更容易健康為目標，持續開發相關健康產品。<br /><br />
                </p>
                <div class="product-link" style="text-align: left">
                  <a :href="louyiai_url"
                    ><input
                      type="submit"
                      style="
                        background-color: #4f4f4f;
                        width: 100px;
                        height: 35px;
                        border: 2px blue none;
                        color: white;
                        border-radius: 2px;
                      "
                      value="前往網站"
                  /></a>
                </div>
              </div>
            </div>
          </div>
          <p></p>
          <div id="shanghai" class="x5x2">
            <div class="row" style="padding: 0 1em">
              <div class="col-md-6" style="padding: 0 1em">
                <div class="">
                  <br /><img
                    src="/img/a09011e1-fd9f-4f3f-9034-fce2367cd00c.jpg"
                    alt=""
                    width="100%"
                    height=""
                  />
                </div>
              </div>
              <div class="col-md-6" style="padding: 0 1em">
                <h3 class="m-b-2" style="text-align: left"></h3>
                <span style="font-size: 21pt"
                  ><span style="font-size: 16px"><br /></span
                  ><br />上海樓易</span
                >
                <p>
                  <br />樓易信息技術(上海)有限公司，由樓中亮醫師於2018
                  年3月創辦。
                </p>
                <p>
                  是一家以中醫理論體系為核心，輔助以AI人工智能和大數據等技術服務，為用戶提供健康診斷報告及健康調理解決方案的創新型AI健康科技企業。<br /><br />
                </p>
                <div class="product-link" style="text-align: left">
                  <a href="https://shanghai.louyiai.com.tw/"
                    ><input
                      type="submit"
                      style="
                        background-color: #4f4f4f;
                        width: 100px;
                        height: 35px;
                        border: 2px blue none;
                        color: white;
                        border-radius: 2px;
                      "
                      class="btn-outline-primary"
                      value="前往網站"
                  /></a>
                </div>
              </div>
            </div>
            <p></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  beforeCreate() {
    if (this.$route.name != "Home") {
      this.$router.replace({ name: "Home" });
    }
  },
  updated() {},
  computed: {
    louyiai_url() {
      return process.env.MIX_LOUYIAI_LINK;
    },
  },
};
</script>