<template>
  <div>
    <div class="topnavbar">
      <div class="topnavbar">
        <div class="container">
          <div id="logo">
            <a
              @click="
                $router.push({
                  name: 'Home',
                })
              "
              ><h1>香港商天訊國際控股集團</h1></a
            >
          </div>
          &nbsp;<!-- 避免將 logo 與 nav 併在一起 -->
          <nav id="nav">
            <ul>
              <li class="first" @click="clickItem('news')">
                <a><span>最新消息</span></a>
              </li>
              <li class="has-subnav">
                <a><span>關於我們</span></a>
                <ul class="subnav">
                  <li class="no-subnav" @click="clickItem('brand-story')">
                    <div>
                      <a><span>品牌故事</span></a>
                    </div>
                  </li>
                  <li class="no-subnav" @click="clickItem('founder')">
                    <div>
                      <a><span>創辦人</span></a>
                    </div>
                  </li>
                </ul>
              </li>
              <li class="has-subnav">
                <a><span>集團企業</span></a>
                <ul class="subnav">
                  <li class="no-subnav" @click="clickItem('shanghai')">
                    <div>
                      <a><span>上海樓易</span></a>
                    </div>
                  </li>
                  <li class="no-subnav" @click="clickItem('louyiai')">
                    <div>
                      <a><span>樓易健康</span></a>
                    </div>
                  </li>
                </ul>
              </li>
              <li class="contact-us">
                <a
                  @click="
                    $router.push({
                      name: 'ContactUs',
                    })
                  "
                  ><span>聯絡我們</span></a
                >
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div id="banner" class="cusbg" data-enable="0">
      <img
        src="/img/home-banner-338b4.jpg?banner&height=3818"
        id="banner-img"
        alt="香港商天訊國際控股集團"
        data-dir="1"
        data-effect="0"
        data-height="3818"
        data-imgs="/img/home-banner-338b4.jpg?banner&height=3818"
        data-link=""
        data-isEnabled="0"
      />
    </div>
    <div class="navbar navbar-fixed-top">
      <div class="container">
        <button type="button" class="rwd-navbar-toggle rwd-collapse">
          <span class="label">選單</span><span class="navbar-icon-bar"></span>
        </button>
        <ul class="nav navbar-nav navbar-right" id="topnav">
          <li id="language" class="dropdown hidden-xs">
            <ul class="dropdown-menu" role="menu"></ul>
          </li>
        </ul>
      </div>
    </div>
    <div id="rwd-side" class="rwd-side rwd-collapse">
      <div class="inner">
        <ul class="topnav"></ul>
        <div class="nav"></div>
        <div class="side"></div>
      </div>
    </div>
    <button class="closed" id="newMenuButton" onclick="onMenuButtonClick(this)">
      <i class="material-icons">menu</i>
      <span>選單</span>
    </button>
    <div id="product-search-modal" class="modal fade">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title" id="psm-title">搜尋產品</h4>
          </div>
          <div class="modal-body">
            <div class="row">
              <div id="product-search-main">
                <input
                  type="text"
                  class="product-keyword"
                  value=""
                  autocomplete="off"
                /><button
                  type="submit"
                  data-loading-text="Search.."
                  class="btn-search"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="row">
              <h4 id="product-serach-msg"></h4>
            </div>
            <div class="hidden text-center" id="product-serach-loading">
              <img src="templates/images/loading.gif" alt="searching-icon" />
            </div>
            <div class="row">
              <div class="col-md-4 media-list">
                <div class="row">
                  <div class="col-md-12">
                    <div id="product-search-cates" class="list-group"></div>
                  </div>
                </div>
              </div>
              <div class="col-md-8 media-list">
                <ul id="product-search-result"></ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      aboutUsOpened: false,
      seasonalOpened: false,
    };
  },
  methods: {
    clickItem(id) {
      const scrollToID = () => {
        if (id) {
          window.scrollTo({
            top: $(`#${id}`)[0].offsetTop,
            left: 0,
            behavior: "smooth",
          });
        }
      };
      const routeName = this.$route.name;
      if (
        routeName != "Home" &&
        event.target.parentElement.parentElement.childElementCount == 1
      ) {
        this.$router.push({ name: "Home" });
        setTimeout(() => {
          scrollToID();
        }, 100);
      } else {
        scrollToID();
      }
    },
  },
};
</script>