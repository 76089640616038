<template>
  <div>
    <div class="fcol fcol-header">
      <div class="container">
        <ol id="breadcrumb"></ol>
        <div class="h1title">
          <h1><span>聯絡我們</span></h1>
        </div>
      </div>
    </div>
    <div class="fcol fcol-1">
      <div class="container">
        <div id="contact-hearder" class="edit-area"></div>
      </div>
    </div>
    <div class="fcol fcol-2">
      <div class="container">
        <span class="signtxt"
          >請您仔細填妥底下的資料,並按下「填寫完可送出」按鈕,將有專人為您服務及回覆</span
        ><span class="marktxt">以下標示 * 為必填</span>
        <div class="form">
          <div class="name-wrap">
            <label>您的大名<span class="mark">*</span></label
            ><input class="name input" type="text" v-model="params.name" />
          </div>
          <div class="company-name-wrap">
            <label>公司名稱</label
            ><input v-model="params.company" class="name input" type="text" />
          </div>
          <div class="company-add-wrap">
            <label>公司地址</label
            ><input v-model="params.address" class="name input" type="text" />
          </div>
          <div class="gender-wrap">
            <label>性別</label
            ><input
              v-model="params.gender"
              name="gender"
              value="先生"
              class="gender inputradio"
              type="radio"
              checked="checked"
            />先生
            <input
              v-model="params.gender"
              name="gender"
              value="小姐"
              class="gender inputradio"
              type="radio"
            />小姐
          </div>
          <div class="tel-day-wrap">
            <label>聯絡電話(日)</label
            ><input
              v-model="params['tel-daytime']"
              class="tel-day input"
              type="text"
            />
          </div>
          <div class="tel-night-wrap">
            <label>聯絡電話(夜)</label
            ><input
              v-model="params['tel-night']"
              class="tel-night input"
              type="text"
            />
          </div>
          <div class="fax-wrap">
            <label>傳真</label
            ><input v-model="params.fax" class="fax input" type="text" />
          </div>
          <div class="mobile-wrap">
            <label>聯絡手機<span class="mark">*</span></label
            ><input v-model="params.mobile" class="mobile input" type="text" />
          </div>
          <div class="mail-wrap">
            <label>電子郵件<span class="mark">*</span></label
            ><input v-model="params.email" class="mail input" type="text" />
          </div>
          <div class="message-wrap">
            <label>留言內容<span class="mark">*</span></label
            ><textarea
              v-model="params.message"
              cols="50"
              rows="7"
              class="message textarea"
            ></textarea>
          </div>
          <div id="grecaptcha"></div>
          <div class="submit-wrap" @click="submit()">
            <input
              name="type"
              value="填寫完可送出"
              class="submit inputbtn"
              type="submit"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ArticleApiHelper from "../../script/Utility/ArticleApiHelper";
export default {
  data() {
    return {
      params: {
        name: "",
        company: "",
        address: "",
        gender: "",
        telDaytime: "",
        telNight: "",
        fax: "",
        mobile: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    submit() {
      loading();
      ArticleApiHelper.contactUs(this.params)
        .then(() => {
          loading(false);
          this.$Message.success("送出成功");
          this.params = {
            name: "",
            company: "",
            address: "",
            gender: "",
            telDaytime: "",
            telNight: "",
            fax: "",
            mobile: "",
            email: "",
            message: "",
          };
        })
        .catch(errorHandler);
    },
  },
};
</script>